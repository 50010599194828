<template>
  <div>
    <div class="search">
      <!-- 左边竖线样式写法 -->
      <div class="titleBox">
        <div class="titleLine" style=""></div>
        <div>{{ searchTitle }}</div>
      </div>
      <div class="search-input">
        <a-input-search
          v-model="searchText"
          placeholder="请输入关键字进行搜索"
          @search="onSearch"
        />
      </div>
    </div>
    <a-list
      item-layout="horizontal"
      :data-source="filteredMenuList"
      :split="false"
    >
      <a-list-item
        slot="renderItem"
        slot-scope="item, index"
        @click="changeColor(item)"
        :class="{ 'active ': activeMenu === item }"
      >
        <div
          :class="{ 'activeIndex ': activeMenu === item }"
          style="
            background-color: #d3d9ff;
            width: 8%;
            height: 8%;
            color: #556bff;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            margin-left: 5%;
            font-weight: 800;
          "
        >
          {{ index + 1 }}
        </div>
        <div
          :class="{ 'activeMenu ': activeMenu === item }"
          style="margin: 0 4%; color: #2d3138; font-weight: 800"
        >
          {{ item }}
        </div>
      </a-list-item>
    </a-list>
  </div>
</template>
<script>
export default {
  props: {
    // 这种写法支持对props成员进行配置
    menuListData: {
      type: Array, //要求传递的类型,大写开头
      required: true, //强制传递,否则报错
      default: "", //不传值时默认传
    },
    searchTitle: {
      type: String, //要求传递的类型,大写开头
      required: true, //强制传递,否则报错
      default: "", //不传值时默认传
    },
  },
  computed: {},
  watch: {
    // 监听输入内容进行数据过滤
    searchText(newval) {
      if (!newval) {
        this.filteredMenuList = this.menuListData;
      } else {
        // 将搜索文本转换为小写以进行不区分大小写的匹配
        const searchTextLower = newval.toLowerCase();
        // 使用 Array.filter 方法过滤数据
        const filteredMenuList = this.menuListData.filter((item) => {
          // 将每个菜单项转换为小写以进行不区分大小写的匹配
          const itemLower = item.toLowerCase();
          // 如果菜单项包含搜索文本，则返回 true，表示该项应该包含在过滤后的结果中
          return itemLower.includes(searchTextLower);
        });
        // 更新组件的数据，以显示过滤后的结果
        this.filteredMenuList = filteredMenuList;
      }
    },
  },
  data() {
    return {
      activeMenu: this.menuListData[0],
      searchText: "",
      filteredMenuList: this.menuListData,
    };
  },
  methods: {
    changeColor(item) {
      this.activeMenu = item;
      this.$emit("changeTableName", item);
    },
    // 搜索
    onSearch(searchText) {
      // 将搜索文本转换为小写以进行不区分大小写的匹配
      const searchTextLower = searchText.toLowerCase();
      // 使用 Array.filter 方法过滤数据
      const filteredMenuList = this.menuListData.filter((item) => {
        // 将每个菜单项转换为小写以进行不区分大小写的匹配
        const itemLower = item.toLowerCase();
        // 如果菜单项包含搜索文本，则返回 true，表示该项应该包含在过滤后的结果中
        return itemLower.includes(searchTextLower);
      });
      // 更新组件的数据，以显示过滤后的结果
      this.filteredMenuList = filteredMenuList;
    },
  },
};
</script>
<style lang="less" scoped>
.active {
  background-color: #e8eef4 !important;
}

.activeIndex {
  background-color: #556bff !important;
  color: #fff !important;
}

.activeMenu {
  color: #556bff !important;
}

.search {
  padding: 3% 5%;

  .titleBox {
    display: flex;
    align-items: center;
    font-size: 0.08rem;
    font-weight: 700;
    color: #2d3138;
    .titleLine {
      height: 0.09rem;
      width: 0.015rem;
      background-color: #2a5cff;
      margin-right: 5px;
    }
  }

  .search-input {
    margin-top: 4%;
  }
}
</style>